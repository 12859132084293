export interface Pairing {
  deviceId: string;
  email: string;
  deviceName: string;
}

export function isPairing(item: any): item is Pairing {
  return (
    typeof (item as Pairing).deviceId === 'string' &&
    typeof (item as Pairing).email === 'string' &&
    typeof (item as Pairing).deviceName === 'string'
  );
}

export function isPairingWithTimestamp(item: any): item is PairingWithTimestamp {
  return (
    typeof (item as PairingWithTimestamp).time === 'number' &&
    isPairing((item as PairingWithTimestamp).pairing)
  );
}

export interface PairingWithTimestamp {
  time: number;
  pairing: Pairing;
}

export interface PairingWithTimeOfDay {
  timeOfDay: string;
  pairing: Pairing;
}

export interface GroupedPairings {
  [key: string]: PairingWithTimeOfDay[];
}
