import { roles } from '../../constants';

export const roleAccessChart = {
  readOnly: ['fetch-twin', 'mower-status'],
  user: ['backend-pairing', 'fetch-twin', 'mower-status', 'map-files'],
  support: [
    'backend-pairing',
    'fetch-twin',
    'delete-twin',
    'mower-status',
    'map-files',
    'remote-debugging',
    'mower-environment',
  ],
};

type RoleChecker = (tool: string) => boolean;

export const createRoleAccessChecker = (role: string): RoleChecker => {
  switch (role) {
    case roles.Admin:
      return (_tool) => true;
    case roles.Support:
      return (tool) => {
        return roleAccessChart.support.includes(tool.split('?')[0].replace(/\//g, ''));
      };
    case roles.User:
      return (tool) => {
        return roleAccessChart.user.includes(tool.split('?')[0].replace(/\//g, ''));
      };
    default:
      return (tool) => {
        return roleAccessChart.readOnly.includes(tool.split('?')[0].replace(/\//g, ''));
      };
  }
};

export const getAvailableEnvironments = (role: string) => {
  switch (role) {
    case roles.Readonly:
    case roles.User:
      return ['qa'];
    case roles.Support:
      return ['dev', 'qa', 'live'];
    default:
      return ['dev', 'qa', 'live'];
  }
};
