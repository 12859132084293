import { MouseEvent } from 'react';
import { Button, Colors } from 'components/UI/Button/Button';
import { StyledButtonContainer, StyledModal, StyledModalBackground } from './styled';

interface Props {
  onOkClickHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancelClickHandler: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const RemoteDebuggingModal = ({ onOkClickHandler, onCancelClickHandler }: Props) => {
  return (
    <StyledModalBackground>
      <StyledModal>
        <h2>Attention!</h2>
        <p>
          By clicking OK you confirm that, in the case of a mower owned by an end customer, you have
          the explicit consent of that customer to activate this functionality for this occasion.
        </p>
        <StyledButtonContainer>
          <Button onClick={onOkClickHandler} widthPercent="35%">
            OK
          </Button>
          <Button onClick={onCancelClickHandler} color={Colors.Secondary} widthPercent="35%">
            Cancel
          </Button>
        </StyledButtonContainer>
      </StyledModal>
    </StyledModalBackground>
  );
};
