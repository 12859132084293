import { useAppSelector } from 'hooks/hooks';
import { StyledUserDisplay, StyledUserName } from './styled';

export const CurrentUserDisplay = () => {
  const { username } = useAppSelector((state) => state.auth.session);

  return <StyledUserDisplay>Logged in as: <br></br> <StyledUserName>{`${username}`}</StyledUserName></StyledUserDisplay>;
};

export default CurrentUserDisplay;
