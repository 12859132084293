import { RemoteDebugSession } from 'models/remoteDebugSessionModel';
import { epochSecondsToDateString } from 'utils/dateUtils/dateUtils';
import {
  StyledTable,
  StyledTableBody,
  StyledTableData,
  StyledTableHead,
  StyledTableHeader,
  StyledTableRow,
} from './styled';

interface Props {
  headers: string[];
  queryData: RemoteDebugSession[];
}

export const RemoteDebugSessionsTable = ({ headers, queryData }: Props) => {
  return (
    <StyledTable>
      <StyledTableHead>
        <StyledTableRow>
          {headers.map((header) => {
            return (
              <StyledTableHeader key={header}>
                <p>{header}</p>
              </StyledTableHeader>
            );
          })}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableBody>
        {queryData.map((session) => {
          return (
            <StyledTableRow key={session.serialNumber}>
              <StyledTableData>
                <p>{session.serialNumber}</p>
              </StyledTableData>
              <StyledTableData>
                <p>{session.requestedBy}</p>
              </StyledTableData>
              <StyledTableData>
                <p>
                  {session.startedAtTimestamp === 0
                    ? 'Session not started yet.'
                    : epochSecondsToDateString(session.startedAtTimestamp)}
                </p>
              </StyledTableData>
              <StyledTableData>
                <p>{epochSecondsToDateString(session.endingTimestamp)}</p>
              </StyledTableData>
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </StyledTable>
  );
};
