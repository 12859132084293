import { combineReducers, configureStore, Middleware, PreloadedState } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { toolsApi } from './api/tools-v1';
import { authReducer } from './authState/authState';
import { envReducer } from './envState/envState';
import { errorReducer } from './errorState/errorState';

const rootReducer = combineReducers({
  toolsApi: toolsApi.reducer,
  auth: authReducer,
  env: envReducer,
  error: errorReducer,
});

export const makeStore = (preloadedState?: PreloadedState<RootState>) => {
  const middleWares: Middleware[] = [toolsApi.middleware];
  if (process.env.NODE_ENV !== 'production') {
    middleWares.push(logger);
  }

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleWares),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
  });
};

export const store = makeStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = typeof store.dispatch;
