import { StyledTextLabel } from '../TextLabel/StyledTextLabel';
import { StyledOption, StyledSelect } from './styled';

interface Props {
  optionList: string[];
  onChangeFunction: (value: string) => void;
  placeholderText?: string;
  width?: string;
  margin?: string;
  value?: string;
  label: string;
}

export const DropdownMenu = ({ optionList, onChangeFunction, placeholderText, width, margin, value = '', label }: Props) => (
  <>
    {/* <label>{label}</label> */}
    <StyledTextLabel margin="none" display="initial">
      {label}
    </StyledTextLabel>
    <StyledSelect
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChangeFunction(event?.target.value)}
      value={value || placeholderText}
      width={width}
      margin={margin}
    >
      {value === '' && (
        <StyledOption disabled hidden>
          {placeholderText}
        </StyledOption>
      )}
      {optionList.map((optionName: string) => (
        <StyledOption key={optionName}>{optionName}</StyledOption>
      ))}
    </StyledSelect>
  </>
);
