import {
  fieldRequiredMessage,
  incorrectDeviceIdMessage,
  invalidRemoteDebugSessionLength,
} from 'assets/messages/errorMessages';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { Button } from 'components/UI/Button/Button';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { FormEventHandler } from 'react';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';

export interface FormInput {
  serialNumber: string;
  endInNrOfDays: number;
}
export interface FormErrors {
  serialNumber?: FieldError;
  endInNrOfDays?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const RemoteDebuggingForm = ({ onSubmit, register, errors, placeholder = '' }: Props) => {
  return (
    <form aria-label="form" onSubmit={onSubmit}>
      <StyledTextLabel htmlFor="serialNumber">Mower Id:</StyledTextLabel>
      <StyledTextInput
        {...register('serialNumber', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          setValueAs: autofillG4ComboardId(),
          pattern: {
            value: /^\d{9}-\d{9}$/,
            message: incorrectDeviceIdMessage,
          },
        })}
        placeholder={placeholder}
      />
      {errors.serialNumber && <StyledFormError>{errors.serialNumber.message}</StyledFormError>}
      <StyledTextLabel htmlFor="endInNrOfDays">End session in nr of days:</StyledTextLabel>
      <StyledTextInput
        type="number"
        {...register('endInNrOfDays', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          valueAsNumber: true,
          min: {
            value: 1,
            message: invalidRemoteDebugSessionLength,
          },
          max: {
            value: 365,
            message: invalidRemoteDebugSessionLength,
          },
        })}
      />
      {errors.endInNrOfDays && <StyledFormError>{errors.endInNrOfDays.message}</StyledFormError>}
      <Button type="submit">Send</Button>
    </form>
  );
};
