import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { APIGatewayError, RTKQueryError, AmcError } from 'models/errors/errorModels';
import { ToolsError } from 'models/errors/ToolsError';

function isAWSError(
  error: RTKQueryError | SerializedError,
): error is Extract<RTKQueryError, { status: number }> {
  return (error as APIGatewayError | AmcError).data !== undefined;
}

export function isAmcError(error: APIGatewayError | AmcError): error is AmcError {
  return (error as AmcError).data.detail !== undefined;
}

function isAPIGatewayError(error: APIGatewayError | AmcError): error is APIGatewayError {
  return (error as APIGatewayError).data.message !== undefined;
}

function isRTKQueryClientSideError(
  error: RTKQueryError | SerializedError,
): error is Exclude<FetchBaseQueryError, { status: number; data: unknown }> {
  return (error as { error: string }).error !== undefined;
}

export const setRTKQueryErrorInfo = (error: RTKQueryError | SerializedError) => {
  const title = 'Data Fetching Error';

  if (isAWSError(error)) {
    if (isAmcError(error)) {
      return [error.data.title, error.data.detail];
    }
    if (isAPIGatewayError(error)) {
      const message = error.status === 403 ? 'The request was unauthorized.' : error.data.message;
      return [title, message];
    }
  }

  if (isRTKQueryClientSideError(error)) {
    return [title, error.error];
  }

  const message = error.message ?? 'An unexpected data fetching error occurred.';

  return [title, message];
};

export function isToolsError(error: unknown): error is ToolsError {
  return (error as ToolsError).title !== undefined;
}
