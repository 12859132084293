import React, { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { MenuItem } from '../NavBar/types';

import { Menu, MenuLink } from './styled';

type Props = {
  menu: MenuItem[];
};

const TabMenu: FC<Props> = ({ menu }) => {
  const { hash, pathname } = useLocation();

  // Active path
  const activePath = useMemo(() => {
    if (hash) {
      return pathname + hash;
    }
    return pathname;
  }, [hash, pathname]);

  // Check active
  const checkActive = useCallback(
    (link: string | undefined) => {
      if (!link) {
        return false;
      }
      return link.includes(activePath);
    },
    [activePath],
  );

  return (
    <Menu>
      {menu.map(({ id, link, text }) => (
        <MenuLink key={id} to={link ?? '#'}>
          {text}
          <motion.svg
            height="4"
            initial={{ width: 0 }}
            transition={{ duration: 0.2 }}
            animate={checkActive(link) ? 'visible' : 'hidden'}
            variants={{
              visible: {
                width: 60,
              },
              hidden: {
                width: 0,
              },
            }}
          >
            <g fill="#F35321" fillRule="nonzero">
              {/* eslint-disable-next-line max-len */}
              <path d="M4.087.338C4.397.118 4.763 0 5.137 0H6v4H.473c-.467 0-.65-.64-.263-.916L4.087.338ZM6 0h48v4H6zM55.913.338A1.814 1.814 0 0 0 54.863 0H54v4h5.527c.467 0 .65-.64.263-.916L55.913.338Z" />
            </g>
          </motion.svg>
        </MenuLink>
      ))}
    </Menu>
  );
};

export default TabMenu;
