import { Pairing, PairingWithTimeOfDay } from 'models/pairingModels';
import { Button } from 'components/UI/Button/Button';
import { StyledPairingView } from './styled';

interface Props {
  pairing: PairingWithTimeOfDay;
  redoPairing: (pairing: Pairing) => void;
}

export const PairingView = ({ pairing, redoPairing }: Props) => {
  const pairDevice = (deviceId: string, email: string, deviceName: string) => {
    const activeDevice = { deviceId, email, deviceName };
    redoPairing(activeDevice);
  };

  return (
    <>
      <hr />
      {pairing.timeOfDay} <br />
      <StyledPairingView>
        <div>
          Mower Id: {pairing.pairing.deviceId} <br />
          Email: {pairing.pairing.email} <br />
          Mower name: {pairing.pairing.deviceName} <br />
        </div>
        <Button
          widthPercent="20%"
          id="pairingViewButton"
          type="button"
          onClick={() =>
            pairDevice(pairing.pairing.deviceId, pairing.pairing.email, pairing.pairing.deviceName)
          }
        >
          Pair
        </Button>
      </StyledPairingView>
    </>
  );
};
