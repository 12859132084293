import React, { ReactNode } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';
import './types';

export const defaultTheme: DefaultTheme = {
  fontFamily: "'Husqvarna Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;",
  colors: {
    primary: '#F35321',
    primary10: 'rgba(243, 83, 33, 0.1)',
    onPrimary: '#FFFFFF',
    secondary: '#EEEEED',
    onSecondary: '#3D3D3C',
    background: '#EEEEED',
    onBackground: '#686867',
    surface: '#FFFFFF',
    onSurface: '#3D3D3C',
    backgroundDark: '#2C2C2B',
    onBackgroundDark: '#FFFFFF',
    grey1: '#f3f2f0',
    grey2: '#e2e3e6',
    grey3: '#d2d2d2',
    grey4: '#D2D2D1',
    grey5: '#595959',
    info: '#07afdc',
    danger: '#dc2832',
    success: '#31b900',
    warning: '#f9b331',
    border: '#979797',
    error: '#ff0000',
    subtle: '#90908F',
  },
};

export const ThemeWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export * from './GlobalStyles';
