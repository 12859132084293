import styled from 'styled-components';
import { breakpoints } from '../../../styles/GlobalStyles';

export const StyledUserDisplay = styled.div`
color: black;
padding-top: 5px;
@media (max-width: ${breakpoints.l}px) {
  display: none;
 }
`;

export const StyledUserName = styled.div`
font-weight: 600;
`;
