import styled from 'styled-components';
import { defaultTheme } from 'styles';

export const StyledPairingHistory = styled.div`
  width: 30%;
  background-color: ${defaultTheme.colors.onPrimary};
  padding: 16px 16px 0 16px;
  border-radius: 16px;
  margin: 0.5% 0% 0% 3%;
  max-height: 100%;
  overflow: auto;
`;
