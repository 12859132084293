import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { Button } from 'components/UI/Button/Button';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { azureLogin, checkSession, operationToolsLogin } from 'store/authState/authState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledLoginContainer } from './styled';

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    session: { authenticated },
    isLoading,
  } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');
  const oauthState = searchParams.get('state');

  // Check if user has active session stored, if so - restore it. Redirect if authenticated.
  useEffect(() => {
    if (!authenticated) {
      dispatch(checkSession());
    }
    if (authenticated) {
      navigate('/backend-pairing', { replace: true });
    }
  }, [dispatch, checkSession, navigate, authenticated]);

  // If user is returned from Microsoft login, contact Login API to complete the login process.
  useEffect(() => {
    if (authorizationCode && oauthState) {
      dispatch(operationToolsLogin(authorizationCode, oauthState));
    }
  }, [authorizationCode, oauthState, dispatch]);

  const loginHandler = useCallback(
    (event: MouseEvent<any>) => {
      event.preventDefault();
      dispatch(azureLogin());
    },
    [dispatch],
  );

  const content = useMemo(() => {
    if (isLoading) {
      // Replace with loading spinner in the future
      return <div>Loading...</div>;
    }
    return (
      <>
        <h3>Welcome! Please login to use the tools.</h3>
        <Button onClick={loginHandler} widthPercent="20%">
          Login
        </Button>
        <div>
          <h4>Welcome to AMC Operation Tools! </h4>
          Access to this application can be requested via ITSP.
          <br />
          If you have further questions or requests, please reach out to AMC through your preferred
          channel and you will be guided to the right people.
        </div>
      </>
    );
  }, [loginHandler, isLoading]);

  return (
    <StyledPageWrapper>
      <StyledLoginContainer>{content}</StyledLoginContainer>
    </StyledPageWrapper>
  );
};
