import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { isStartedAtTimestampResult } from 'models/apiModels';
import { RTKQueryError } from 'models/errors/errorModels';

async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function remoteDebugSessionStarted(
  serialNumber: number,
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, RTKQueryError, {}>>,
): Promise<boolean> {
  const startedAtTimestampResult = await baseQuery({
    url: `/tools/v1/mowers/${serialNumber}-999999999/remote-debug-session`,
  });
  return (
    isStartedAtTimestampResult(startedAtTimestampResult.data) &&
    startedAtTimestampResult.data.startedAtTimestamp !== 0
  );
}

export async function remoteDebugSessionStartedWithRetry(
  serialNumber: number,
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, RTKQueryError, {}>>,
  retries = 15,
): Promise<boolean> {
  return remoteDebugSessionStarted(serialNumber, baseQuery).then(async (sessionStarted) => {
    if (!sessionStarted && retries > 0) {
      await sleep(13000);
      return remoteDebugSessionStartedWithRetry(serialNumber, baseQuery, retries - 1);
    }
    return sessionStarted;
  });
}

async function mowerLogIn(
  serialNumber: number,
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, RTKQueryError, {}>>,
): Promise<number> {
  const mowerLogInResult = await baseQuery({
    url: `/tools/v1/mowers/${serialNumber}-999999999/proxyincloud/mower-log-in`,
    method: 'POST',
    body: { level: 'advanced' },
  });
  if (mowerLogInResult.error?.status === 504 || mowerLogInResult.error?.status === 500) {
    return mowerLogInResult.error.status;
  }
  return typeof mowerLogInResult.error?.status === 'number' ? mowerLogInResult.error.status : 200;
}

export async function mowerLogInWithRetry(
  serialNumber: number,
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<QueryReturnValue<unknown, RTKQueryError, {}>>,
  retries = 3,
): Promise<boolean> {
  return mowerLogIn(serialNumber, baseQuery).then(async (isValidError) => {
    if ((isValidError === 500 || isValidError === 504) && retries > 0) {
      return mowerLogInWithRetry(serialNumber, baseQuery, retries - 1);
    }
    if (isValidError === 200) {
      return true;
    }
    return false;
  });
}
