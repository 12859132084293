import { Mower, GDPRinfo, LongLat } from 'models/apiModels';

function positionInfoToString(positions: LongLat[]): string {
  let info = '';
  positions.forEach((position) => {
    info += ` ${position.longitude}, ${position.latitude}\n`;
  });
  return info;
}

function mowerInfoToString(mowers: Mower[]): string {
  let info = '';
  mowers.forEach((mower) => {
    const centralPositionInfo = mower.centralPosition
      ? ` ${mower.centralPosition.longitude}, ${mower.centralPosition.latitude}\n`
      : ' No data stored \n';

    const positionsInfo = mower.positions
      ? positionInfoToString(mower.positions)
      : ' No data stored \n';

    info += '-------------------------------------------------------------------\n';
    info += `Name:\n ${mower.name}\nCentral position (longitude, latitude):\n${centralPositionInfo}Positions (longitude, latitude):\n${positionsInfo}`;
  });
  return info;
}

export function gdprInfoToString(data: GDPRinfo): string {
  const commonInfo = `Name: ${data.name}\nEmail: ${data.email}\nCountry: ${data.country}\nMowers:\n`;
  const mowerInfo = data.mowers ? mowerInfoToString(data.mowers) : ' No data stored \n';
  return commonInfo + mowerInfo;
}
