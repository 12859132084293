import { ToolsError } from 'models/errors/ToolsError';
import { isAmcError, isToolsError } from 'utils/errorUtils';

export const getAzureLoginUrl = async () => {
  let azureUrl: string = '';
  try {
    const response = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/v1/login`, {
      credentials: 'include',
    });
    const responseBody = await response.json();
    azureUrl = responseBody.azureUrl;
    if (!azureUrl) {
      const { error } = responseBody;
      if (isAmcError(error)) {
        throw new ToolsError(error.data.title, error.data.detail);
      }
      throw new ToolsError('Login API Error', error.message);
    }
  } catch (error) {
    if (isToolsError(error)) {
      throw error;
    }
    throw new Error('An unexpected Azure error occurred.');
  }
  return azureUrl;
};
