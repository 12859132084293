import { toast } from 'react-toastify';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export const showToast = (title: string, message: string, type = ToastType.ERROR) => {
  toast[type](<div>{title}<br />{message}</div>, {
    position: 'bottom-left',
    autoClose: 6000,
    hideProgressBar: true,
  });
};

export const showErrorToast = (title: string, message: string) => showToast(title, message, ToastType.ERROR);
export const showWarningToast = (title: string, message: string) => showToast(title, message, ToastType.WARNING);
export const showInfoToast = (title: string, message: string) => showToast(title, message, ToastType.INFO);
export const showSuccessToast = (title: string, message: string) => showToast(title, message, ToastType.SUCCESS);
