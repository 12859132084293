import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toolsApi } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { displayError, handleRTKQueryError } from 'store/errorState/errorState';
import { SidebarMessage } from 'components/fragments/SidebarError/SidebarError';
import ReactJson from 'react-json-view';
import { Pairing, PairingWithTimestamp } from 'models/pairingModels';
import {
  getPairingHistory,
  storePairingInHistory,
} from 'utils/localStorageUtils/localStorageUtils';
import { ToolsError } from 'models/errors/ToolsError';
import BackendPairingForm from '../PairingForm/BackendPairingForm';
import ToolSideBar from '../../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../../constants';
import { StyledBackendPairingPresentation, StyledLeftWrapper } from './styled';
import { PairingHistory } from '../PairingHistory';

export const BackendPairingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentEnvironment = searchParams.get('env') ?? 'qa';
  const paramDeviceId = searchParams.get('deviceId') ?? undefined;
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  const paramEmail = searchParams.get('email') ?? undefined;
  const [email, setEmail] = useState<string | undefined>(paramEmail);
  const paramDeviceName = searchParams.get('deviceName') ?? undefined;
  const [deviceName, setName] = useState<string | undefined>(paramDeviceName);
  const [history, setHistory] = useState<PairingWithTimestamp[]>(getPairingHistory());

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const [performBackendPairing, { data, isLoading, isSuccess, error: mutationError }] =
    toolsApi.useBackendPairingMutation({ fixedCacheKey: 'backend-pairing-mutation' });

  const onSubmit: SubmitHandler<Pairing> = (formData: Pairing) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = {
      ...oldParams,
      deviceId: formData.deviceId,
      email: formData.email,
      deviceName: formData.deviceName,
    };
    setSearchParams(params, { replace: true });
    setDeviceId(formData.deviceId);
    setEmail(formData.email);
    setName(formData.deviceName);

    performBackendPairing(formData)
      .unwrap()
      .then(() => {
        try {
          storePairingInHistory(formData);
          setHistory([
            ...history,
            {
              time: Date.now(),
              pairing: formData,
            },
          ]);
        } catch (error) {
          if (ToolsError.isToolsError(error)) {
            dispatch(displayError(error));
          }
        }
      });
  };

  useEffect(() => {
    if (mutationError) {
      dispatch(handleRTKQueryError(mutationError));
    }
  }, [mutationError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<Pairing>({
    defaultValues: {
      deviceId,
      email,
      deviceName,
    },
  });

  return (
    <>
      <ToolSideBar>
        {currentEnvironment === 'live' ? (
          <SidebarMessage
            message="You cannot use this tool in the chosen environment"
            textColor="red"
          />
        ) : (
          <BackendPairingForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={formErrors}
            placeholders={{
              deviceId: '123456789-123456789',
              email: 'your@email.ad',
              deviceName: 'My cool device',
            }}
          />
        )}
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledBackendPairingPresentation>
          <StyledLeftWrapper>
            <h1>Backend Pairing</h1>
            {isLoading && !mutationError && <h4>Loading response for {deviceId}...</h4>}
            {mutationError && (
              <div>
                <h2>Pairing failed</h2>
              </div>
            )}
            {!(isLoading && mutationError) && isSuccess && data && (
              <>
                <h3>Pairing successful!</h3>
                <p>Response data:</p>
                <ReactJson src={data} displayObjectSize={false} collapsed={1} name={false} />
              </>
            )}
            <hr />
            <h4>Instructions</h4>
            <p>
              To use this tool, choose the environment in the page top panel to which the device is
              currently pointing.
            </p>
            <p>
              In the form, the device ID for a G3 device is the nine-digit serial number of the
              device, followed by a dash,
            </p>
            <p>
              followed by the nine-digit comboard ID. For G4 devices, just input the nine-digit
              serial number of the device.
            </p>
            <p>
              The email is the one connected to the account you wish to pair with, and Device Name
              is an arbitrary name for your device.
            </p>
            <p>
              To the right a history of all pairings made with this browser is shown. This history
              is erased if you clear your browser data (or LocalStorage specifically).
            </p>
            <h4>
              This pairing will not produce events to the husqIoT bus. A Jira has been created for
              this, and when implemented information will be sent out.
            </h4>
          </StyledLeftWrapper>
          <PairingHistory history={history} redoPairing={onSubmit} />
        </StyledBackendPairingPresentation>
      </StyledPageWrapper>
    </>
  );
};

export default BackendPairingPage;
