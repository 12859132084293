import { Pairing, PairingWithTimestamp } from 'models/pairingModels';
import { groupPairingsByDate } from 'utils/pairingUtils/pairingUtils';
import { PairingsAtDateView } from '../PairingsAtDateView';
import { StyledPairingHistory } from './styled';

interface Props {
  history: PairingWithTimestamp[];
  redoPairing: (pairing: Pairing) => void;
}

export const PairingHistory = ({ history, redoPairing }: Props) => {
  const groupedPairings = groupPairingsByDate(history);
  return (
    <StyledPairingHistory>
      <h1>Pairing History</h1>
      {Object.keys(groupedPairings)
        .reverse()
        .map((date, i) => (
          <PairingsAtDateView
            key={date}
            date={date}
            pairings={groupedPairings[date]}
            expanded={i === 0}
            redoPairing={redoPairing}
          />
        ))}
    </StyledPairingHistory>
  );
};
