import styled from 'styled-components';

export const StyledBackendPairingPresentation = styled.div`
  height: 100%;
  h2 {
    color: red;
  }
  h3 {
    color: green;
  }
  display: flex;
`;

export const StyledLeftWrapper = styled.div`
  width: 70%;
`;
