export function insertTextInTheSVGFile(svgFile: string, textToAdd: string, regex: RegExp) {
  const modifiedString = svgFile.replace(regex, `$&${textToAdd}`);
  return modifiedString;
}

export function getMapData(originalFile: string, mapType: string) {
  const allPointsRegex = /points="([^"]*)"/g;
  const pointsValuesRegex = /([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/g;
  const pointsArr: number[] = [];
  const insertTextInSvg = /<svg[^>]*/i;
  const insertTextInPolyline = /<polyline[^/>]*/g;
  const textToAddInPolyline = ' fill = "none" ';

  const allPoints = originalFile.match(allPointsRegex);
  allPoints?.forEach((pointRow) => {
    const pointsValues = pointRow.match(pointsValuesRegex);
    pointsValues?.forEach((point) => {
      pointsArr.push(Number(point));
    });
  });

  const xyPoints = Math.min(...pointsArr);
  const widthHeightPoints = Math.max(...pointsArr) - xyPoints;
  const textToAddInSvgHeader = ` ${
    mapType === 'generatedMap' ? '' : 'xmlns="http://www.w3.org/2000/svg"'
  } viewBox=" ${xyPoints} ${xyPoints} ${widthHeightPoints} ${widthHeightPoints}" `;

  const modifiedFileWithFillNone = insertTextInTheSVGFile(
    originalFile,
    textToAddInPolyline,
    insertTextInPolyline,
  );
  const modifiedFileOnSvgHeader = insertTextInTheSVGFile(
    modifiedFileWithFillNone,
    textToAddInSvgHeader,
    insertTextInSvg,
  );

  const blob = new Blob([modifiedFileOnSvgHeader], { type: 'image/svg+xml' });
  const mapUrl = URL.createObjectURL(blob);

  return mapUrl;
}
