import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from '../../../styles/GlobalStyles';

export const Menu = styled.div`
  display: flex;
  min-height: 48px;
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 0;
  min-width: 60px;
  @media (max-width: ${breakpoints.l}px) {
    padding: 8px;
    font-size: 16px;
    min-width: 40px;
    border-right: 1px solid black;
  }
`;
