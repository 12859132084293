import styled, { DefaultTheme } from 'styled-components';
import { boxShadows } from '../../../styles';

export enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
  Surface = 'surface',
}

type ButtonProps = {
  color: Colors;
  marginRight?: boolean;
  widthPercent?: string;
};

// Render background
function renderBackground(theme: DefaultTheme, color: Colors) {
  if (color === Colors.Secondary) {
    return theme.colors.secondary;
  }
  if (color === Colors.Surface) {
    return theme.colors.surface;
  }
  return theme.colors.primary;
}

// Render color
function renderColor(theme: DefaultTheme, color: Colors) {
  if (color === Colors.Secondary) {
    return theme.colors.onSecondary;
  }
  if (color === Colors.Surface) {
    return theme.colors.onSurface;
  }
  return theme.colors.onPrimary;
}

export const StyledButton = styled.button<ButtonProps>`
  width: ${({ widthPercent }) => widthPercent};
  letter-spacing: 0.08em;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  padding: 5px 20px;
  border-radius: 12px;
  margin: 10px auto;
  color: ${({ theme, color }) => renderColor(theme, color)};
  background-color: ${({ theme, color }) => renderBackground(theme, color)};
  border: 1px solid ${({ theme, color }) => renderBackground(theme, color)};
  box-shadow: ${({ color }) => (color !== Colors.Surface ? boxShadows.normal : 'none')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: pointer;
`;
