import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { StyledOperationToolInfoPresentation } from './styled';

export default function OperationToolInfoPage() {
  return (
    <StyledPageWrapper>
      <StyledOperationToolInfoPresentation>
        <h1>Information</h1>
        <p>
          For information about the application, such as roles, features, roadmap, wish list and
          more, please visit the Operation Tools Confluence page.
        </p>
        <p>
          If you have any further questions or would like to report issues or request new features,
          please write in the &quot;amc-support&quot; Slack channel or contact the AMC Backend team
          through Microsoft Teams.
        </p>
      </StyledOperationToolInfoPresentation>
    </StyledPageWrapper>
  );
}
