import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from 'models/authModels';
import { ToolsError } from 'models/errors/ToolsError';
import { Dispatch } from 'react';
import { getAzureLoginUrl } from 'services/authService';
import { isToolsError } from 'utils/errorUtils';
import { roles } from '../../constants';

interface UserInfo {
  role: string;
  username: string;
}

const initialState: AuthState = {
  session: {
    authenticated: process.env.NODE_ENV === 'development',
    role: process.env.NODE_ENV === 'development' ? roles.Admin : roles.Unauthorized,
    username: '',
  },
  isLoading: false,
  error: undefined,
};
/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLogin: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action: PayloadAction<UserInfo>) => {
      sessionStorage.setItem('role', action.payload.role);
      sessionStorage.setItem('username', action.payload.username);
      state.session.role = action.payload.role;
      state.session.username = action.payload.username;
      state.session.authenticated = true;
      state.isLoading = false;
    },
    loginFailed: (state, action: PayloadAction<ToolsError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    logOut: (state) => {
      state.session.authenticated = false;
      state.session.role = roles.Unauthorized;
      state.session.username = '';
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('username');
    },
  },
});
/* eslint-enable no-param-reassign */
export const authReducer = authSlice.reducer;
export const { startLogin, loginSuccess, loginFailed, logOut } = authSlice.actions;

export const azureLogin = () => async (dispatch: Dispatch<any>) => {
  dispatch(startLogin());
  try {
    const azureUrl = await getAzureLoginUrl();
    window.location.assign(azureUrl);
  } catch (error: any) {
    if (isToolsError(error)) {
      dispatch(loginFailed(error));
    }
    dispatch(loginFailed(ToolsError.fromError('Tools Login Error', error)));
  }
};

export const operationToolsLogin =
  (authorizationCode: string, state: string) => async (dispatch: Dispatch<any>) => {
    dispatch(startLogin());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOGIN_API_URL}/v1/authorize?code=${authorizationCode}&state=${state}`,
        {
          credentials: 'include',
        },
      );
      const { role, username } = await response.json();
      if (Object.values(roles).includes(role) && username) {
        dispatch(
          loginSuccess({
            role,
            username,
          }),
        );
      } else {
        dispatch(loginFailed(new ToolsError('Tools Login Error', 'Invalid role.')));
      }
    } catch (error: any) {
      dispatch(loginFailed(ToolsError.fromError('Tools Login Error', error)));
    }
  };

export const checkSession = () => (dispatch: Dispatch<any>) => {
  try {
    const role = sessionStorage.getItem('role');
    const username = sessionStorage.getItem('username');
    if (role && username) {
      dispatch(loginSuccess({ role, username }));
    }
  } catch (error: any) {
    dispatch(loginFailed(ToolsError.fromError('Session Check Error', error)));
  }
};
