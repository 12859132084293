import { GroupedPairings, PairingWithTimestamp } from 'models/pairingModels';

function transformToDateTimeRepresentation(pairing: PairingWithTimestamp): GroupedPairings {
  const date = new Date(pairing.time);
  const transformed = {
    [date.toLocaleDateString('sv-SE')]: [
      {
        timeOfDay: date.toLocaleTimeString(),
        pairing: pairing.pairing,
      },
    ],
  };
  return transformed;
}

export function groupPairingsByDate(pairings: PairingWithTimestamp[]) {
  return pairings.reduce<GroupedPairings>((acc, curr) => {
    const dateTimePairing = transformToDateTimeRepresentation(curr);
    const dateKey = Object.keys(dateTimePairing)[0];
    if (Object.keys(acc).includes(dateKey)) {
      acc[dateKey].unshift(...dateTimePairing[dateKey]);
      return acc;
    }
    return { ...acc, ...dateTimePairing };
  }, {});
}
