import { createGlobalStyle, DefaultTheme } from 'styled-components';

import husqvarnaBoldWoff from '../assets/fonts/Husqvarna-Gothic-Bold.woff';
import husqvarnaBoldWoff2 from '../assets/fonts/Husqvarna-Gothic-Bold.woff2';
import husqvarnaBoldItalicWoff from '../assets/fonts/Husqvarna-Gothic-Bold-Italic.woff';
import husqvarnaBoldItalicWoff2 from '../assets/fonts/Husqvarna-Gothic-Bold-Italic.woff2';
import husqvarnaRegularWoff from '../assets/fonts/Husqvarna-Gothic-Regular.woff';
import husqvarnaRegularWoff2 from '../assets/fonts/Husqvarna-Gothic-Regular.woff2';
import husqvarnaRegularItalicWoff from '../assets/fonts/Husqvarna-Gothic-Regular-Italic.woff';
import husqvarnaRegularItalicWoff2 from '../assets/fonts/Husqvarna-Gothic-Regular-Italic.woff2';
import husqvarnaLightWoff from '../assets/fonts/Husqvarna-Gothic-Light.woff';
import husqvarnaLightWoff2 from '../assets/fonts/Husqvarna-Gothic-Light.woff2';
import husqvarnaLightItalicWoff from '../assets/fonts/Husqvarna-Gothic-Light-Italic.woff';
import husqvarnaLightItalicWoff2 from '../assets/fonts/Husqvarna-Gothic-Light-Italic.woff2';

// Box Shadows
export const boxShadows = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  normal:
    '0 1px 3px 0 rgba(0, 0, 0, 0.18), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
  xxl: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
};

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1920,
};

export type ThemeColor = keyof DefaultTheme['colors'];
export const colorTransitionDuration = '300ms';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 700;
    src:
      url(${husqvarnaBoldWoff2}) format('woff2'),
      url(${husqvarnaBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 700;
    font-style: italic;
    src:
      url(${husqvarnaBoldItalicWoff2}) format('woff2'),
      url(${husqvarnaBoldItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 400;
    src:
      url(${husqvarnaRegularWoff2}) format('woff2'),
      url(${husqvarnaRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 400;
    font-style: italic;
    src:
      url(${husqvarnaRegularItalicWoff2}) format('woff2'),
      url(${husqvarnaRegularItalicWoff}) format('woff');
  }


  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 300;
    src:
      url(${husqvarnaLightWoff2}) format('woff2'),
      url(${husqvarnaLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 300;
    font-style: italic;
    src:
      url(${husqvarnaLightItalicWoff2}) format('woff2'),
      url(${husqvarnaLightItalicWoff}) format('woff');
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background};
  }

  body > div {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  p,
  div,
  input,
  label, 
  button {
    font-family: ${({ theme }) => theme.fontFamily};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: bold;
  }

  h1 {
    margin: 8px 0 16px 0;
    @media (min-width: ${breakpoints.s}px) {
      font-size: 1.8rem;
    }
  }

  h2 {
    @media (min-width: ${breakpoints.s}px) {
      font-size: 1.6rem;
    }
  }
`;
