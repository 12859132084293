import { StyledSidebarMessage } from './styled';

type SidebarMessageProps = {
  textColor: string,
  message: string
};

export const SidebarMessage = ({ textColor, message }: SidebarMessageProps) => {
  return (
    <StyledSidebarMessage color={textColor}>
      <h3>{message}</h3>
    </StyledSidebarMessage>
  );
};
