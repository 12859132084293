import styled from 'styled-components';
import { breakpoints } from '../../../styles/GlobalStyles';

export const StyledDropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 20px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 4px 0px 0px 0px;
  }
`;

export const DropdownMenu = styled.div``;

export const TargetedEnvironment = styled.div`
color: ${({ theme }) => theme.colors.onSurface};
background-color: ${({ theme }) => theme.colors.background};
border-top-left-radius: 12px;
border-top-right-radius: 12px;
font-weight: bold;
text-transform: uppercase;
text-decoration: none;
font-size: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 12px 20px 0;
min-width: 60px;
@media (max-width: ${breakpoints.l}px) {
  padding: 8px;
  font-size: 16px;
  min-width: 40px;
  border-right: 1px solid black;
}
`;
