import { StyledToolSidebar } from './styled';

export const ToolSidebar: React.FC = ({ children }) => {
  return (
    <StyledToolSidebar>
      {children}
    </StyledToolSidebar>
  );
};

export default ToolSidebar;
