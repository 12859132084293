/* eslint-disable max-len */
export const mockedTwin = {
  session: {
    mowerIp: '1234567891423',
    socketId: '__vertx.net.12c2b0ab-a79f-46d9-a76e-e950b8fa8705',
    timestamp: '2022-07-06T10:51:41.092Z',
    gatewayId: 'MowerGatewayG4Svc-5835d9dd-c523-431a-8fb4-ba68952b7df9',
    gatewayVersion: 'v2',
    mowerId: '999999999',
    modelFile: 'Main-App.json',
    channelId: 2100,
    sessionState: 'SUBSCRIBED',
  },
  mower: {
    mowerId: '999999999',
    iprId: 'ee1ea20533cc',
    modelFile: 'Main-App.json',
    timestamp: '2020-06-10T11:44:15.581Z',
    generation: 'G4',
  },
  product: {
    mowerId: '999999999',
    iprId: 'e-1ab7a20533cc',
    createdBy: 'TEST_DATA_IMPORT',
    productId: '5f78a2b50',
    productModel: 'AM550 EPOS',
    productModelId: '291e291',
    productName: 'HUSQVARNA AUTOMOWER® 550 EPOS',
    articleId: '970312',
    timestamp: '2020-07-01T10:29:50.933Z',
  },
  timestamp: '2022-07-06T11:18:57.657Z',
  version: 2960334,
  metadata: {
    Twin: {
      Product: {
        timestamp: '2020-07-01T10:29:50.813403Z',
      },
    },
    Battery: {
      ChargeLevel: {
        params: {
          timestamp: '2020-09-18T10:17:20.866058Z',
          retries: 0,
          supportEvents: true,
        },
      },
    },
    Authentication: {
      LoginLevel: {
        params: {
          timestamp: '2022-07-06T10:15:58.297717Z',
        },
      },
      OperatorLogin: {
        params: {
          timestamp: '2020-11-10T03:43:45.215285Z',
        },
      },
    },
    MowerApp: {
      Error: {
        awaiting: false,
      },
    },
    Planner: {
      Override: {
        params: {
          timestamp: '2022-07-06T11:18:46.052060Z',
        },
      },
    },
    SiteMap: {
      SiteMapFile: {
        params: {
          dirty: false,
          timestamp: '2022-07-03T14:32:20.213228Z',
          size: 2876,
          checksum: 2906672124,
          lastModified: '2022-07-03T14:32:20.213228Z',
        },
      },
    },
    AutomowerConnect: {
      ConnectionStats: {
        params: {
          timestamp: '2022-02-07T11:22:55.501551Z',
        },
      },
    },
    ConnectionStats: {
      Update: {
        params: {
          timestamp: '2022-07-05T14:28:15.743031Z',
        },
      },
    },
  },
  attributes: {
    Authentication: {
      InitiateAuthenticationV2: {
        params: {
          keyId: 1,
          challenge: 'oH9DJR/vke13bo=',
        },
      },
      ChallengeResponseV2: {
        params: {
          authenticationSuccessful: true,
        },
      },
      LoginLevel: {
        params: {
          loginLevel: 'IAUTHENTICATION_LEVEL',
        },
      },
    },
    AutomowerConnect: {
      GetAllSettings: {
        params: {
          available: true,
          enabled: true,
        },
      },
      ConnectionStats: {
        params: {
          connects: 55,
          disconnects: 50,
          disconnectsByUser: 0,
          upTime: 2622667,
          downTime: 5300,
        },
      },
    },
  },
  modelFile: 'P2Z_001.json',
  editorFile: 'P2Z_001.json',
};

export const mockedStatus = {
  connected: true,
  pendingCaching: false,
  hdop: 0,
  gsmRssi: 0,
  lastErrorCode: 0,
  lastErrorCodeTimestamp: 0,
  configChangeCounter: 0,
  hostMessage: 0,
  nextStartTimestamp: 0,
  nextStartSource: 'NO_SOURCE',
  operatingMode: 'AUTO',
  batteryPercent: 100,
  timestamp: 1692627413977,
  debugInfo: null,
  positions: [
    { latitude: 57.7895786, longitude: 14.2814202, gpsStatus: 'USING_GPS_MAP' },
    { latitude: 57.7896803, longitude: 14.2815381, gpsStatus: 'USING_GPS_MAP' },
    { latitude: 57.7901137, longitude: 14.2818005, gpsStatus: 'USING_GPS_MAP' },
    { latitude: 57.7899954, longitude: 14.2817831, gpsStatus: 'USING_GPS_MAP' },
  ],
  timeBetweenPositions: 30,
  mowerId: '999999999',
  mowerStatus: {
    mode: 'MAIN_AREA',
    state: 'STOPPED',
    activity: 'NOT_APPLICABLE',
    restrictedReason: 'NOT_APPLICABLE',
    type: 'NOT_APPLICABLE',
    overrideAction: 'NOT_ACTIVE',
  },
  errorConfirmable: false,
  firmwareVersion: '59P1660.30',
  fotaDesiredFirmwareVersion: '',
  fotaState: 'IDLE',
  fotaConnectionStatus: 'IFOTA_CONNECTION_STATUS_NOT_CONNECTED',
  csFotaDesiredFirmwareVersion: '',
  csFotaState: 'ICSFOTA_STATE_DISABLED',
  csFotaConnectionStatus: null,
  lonaState: 'ILONA_STATE_READY',
  lonaScanProgress: 100,
  externalRestrictionReason: -1,
  overrideStartTime: 0,
  overrideDuration: 0,
  startupSequenceRequired: null,
  swUpdateRequired: null,
};

export const mockedGdprReport = {
  name: 'Pablo Pikachu',
  email: 'habo_mc-burner98@yahoo.com',
  country: 'Neverland',
  mowers: [
    {
      name: 'Winston',
      centralPosition: { latitude: 57.7899954, longitude: 14.2817831 },
      positions: [
        { latitude: 57.7895786, longitude: 14.2814202 },
        { latitude: 57.7896803, longitude: 14.2815381 },
        { latitude: 57.7901137, longitude: 14.2818005 },
      ],
    },
    {
      name: 'Hasse Alfredsson',
    },
  ],
};

export const mockedMapFile = `<svg>
<polygon fill="green" id="117F676B-0F5D-438B-82F6-A7090EFCFFE9" points="-56208,41227 -41371,31002 -25523,20122 -25141,17666 -3056,2580 -3105,574 -4072,-1640 -2951,-2333 -4183,-4479 -4804,-5819 -5362,-7680 -5520,-9773 -5106,-12340 -12481,-24865 -29884,-55183 -47165,-85249 -51564,-92957 -53205,-91274 -60167,-84914 -60468,-84362 -104854,-55212 -112588,-46050 -112554,-43317 -112324,-40709 -111745,-39623 -110600,-38722 -109833,-37868 -105701,-32495 -105191,-32760 -68063,24127 -68457,24447 -59579,37733" stroke="green" title="Hauptfeld" />
<polygon fill="red" id="7C61C06A-A503-4414-873C-0B54FE021985" points="-9447,-10625 -23809,-32815 -23627,-32887 -9365,-10885" stroke="red" title="Bank Norden" />
<polygon fill="red" id="F985151C-5F43-42D0-97B0-ECC83E7CBD77" points="-24975,-34778 -26592,-37314 -25583,-37913 -23889,-35319" stroke="red" title="Trainer nord" />
<polygon fill="red" id="C61BE97A-22B0-482F-927C-E0C976E865C0" points="-27770,-41580 -29041,-43276 -27311,-44531 -26085,-42622" stroke="red" title="Bewässerung" />
<polygon fill="red" id="9344C201-038B-4BAF-BC25-A07F68CC3DB2" points="-31770,-45251 -33322,-47643 -32151,-48327 -30631,-45815" stroke="red" title="Trainer Süd" />
<polygon fill="red" id="AE27674F-0A4D-4166-B8C9-D5E9656A7864" points="-34425,-49447 -34488,-49388 -48952,-71676 -48934,-71701" stroke="red" title="Bänke Süd" />
<polygon fill="green" id="3C4EA64B-0728-4201-8774-8971CC890390" points="-5633,-11308 -4993,-12880 -4777,-13812 -4370,-14872 -3245,-16203 -2166,-17200 25,-18749 1007,-19245 2748,-20109 5296,-21819 8665,-23863 9655,-25115 9502,-26424 18666,-32124 17425,-34416 17682,-34541 18397,-33643 24492,-37276 23790,-38400 23991,-38608 24805,-37328 26283,-37161 30663,-39758 34408,-42129 41005,-46443 42824,-47965 44093,-49270 47212,-52981 47047,-55208 46885,-56496 45993,-57875 45637,-59260 4513,-128947 3882,-129467 2185,-129425 151,-127842 -3175,-125601 -8209,-122671 -13524,-119582 -20419,-115618 -19695,-114536 -19989,-114348 -20656,-115482 -26750,-111718 -26013,-110509 -26291,-110417 -26758,-111104 -29660,-109296 -31447,-108024 -33374,-106699 -48511,-97093 -49561,-96331 -51436,-94472 -52499,-92257" stroke="green" title="Trainingsplatz" />
<polygon fill="green" id="59C3936B-A983-4BB5-BA56-DF5EEFD7B68D" points="-18596,26245 -20248,23711 -20753,24054 -22149,24766 -24431,25763 -26699,21432 -31960,24921 -31039,26372 -40244,32586 -41832,30539 -56810,40537 -55458,42113 -52123,44088 -50357,45047 -48356,45578 -46782,45333 -43342,44254 -39293,42670 -36822,41219 -31387,38327 -28985,37172 -27272,34691 -26472,32912 -24762,29234" stroke="green" title="Vorplatz" />
<polyline id="908475A9-B445-48A9-B84E-8276ED0B3B05" points="-17854,24115 -21226,26168 -25751,28095 -37938,35777 -45088,37294 -49855,31266" stroke="blue" stroke-width="3000" title="Hauptfeld" />
<polyline id="3F0A5E8F-7181-409C-80C1-74CB48C21BA1" points="-17854,24115 -21433,25878 -26022,27785 -40557,35227 -43943,30761 -28509,21036 -26349,18632 -25235,16650 -6687,3205 -5924,-4594 -6680,-10598 -6238,-17897" stroke="blue" stroke-width="3000" title="Trainingplatz" />
<polyline id="41178D35-AF7C-4032-A946-2E439C61D32A" points="-17854,24115 -18866,25278 -19526,25625 -21021,26419" stroke="blue" stroke-width="3000" title="Transportweg Vorplatz" />
<polyline id="D29851CD-3A72-4BDD-A3E2-E4BF835B5544" points="-17854,24115 -21935,27153 -27191,29857 -42150,36383 -47216,30458 -41580,20247 -13248,-3899 -8069,-9193" stroke="cyan" stroke-width="2000" title="Wartungspunkt" />
<polyline id="CF2629F8-5749-4086-A267-DE338A20C74C" points="-19325,21732 -17854,24115" stroke="yellow" />
</svg>`;

export const mockedPairingResponse = {
  mowerId: '123456789-987654321',
  userId: 'uuid-user-id',
  mowerName: 'Grandma',
  mowerdeviceId: '123456789',
  timeStamp: 1234567890123,
};

export const mockedRemoteDebugSessions = [
  {
    serialNumber: 321654987,
    requestedBy: 'bildt_different@carlbildt.se',
    endingTimestamp: 1698362168000,
    startedAtTimestamp: 1674913436000,
  },
  {
    serialNumber: 120654789,
    requestedBy: 'mona_om_alla@sahlin.se',
    endingTimestamp: 1698367798000,
    startedAtTimestamp: 1650203861000,
  },
];

export const mockedAllowedInLiveFalse = {
  isAllowed: false,
};

export const mockedAllowedInLiveTrue = {
  isAllowed: true,
};

export const mockedError = {
  errors: {
    id: 'f41d9bbd-abc3-4c4b-b68c-b0079bb10820',
    statusCode: 500,
    title: 'Internal Server error',
    detail: 'Could not fulfill request. Please try again later.',
  },
};

export const mockedTimeOut = {
  message: 'Endpoint request timed out',
};

export const mockedstartedAtTimestampZero = {
  startedAtTimestamp: 0,
};

export const mockedstartedAtTimestampStarted = {
  startedAtTimestamp: 125672364573526,
};
