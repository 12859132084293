import styled from 'styled-components';

type SidebarMessageProps = {
  color: string
};

export const StyledSidebarMessage = styled.div<SidebarMessageProps>`
  padding: 20px;
  color: ${({ color }) => color};
`;
