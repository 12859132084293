import React, { useContext, FC } from 'react';
import { motion, Transition } from 'framer-motion';
import { ThemeContext } from 'styled-components';
import { Hamburger, Button } from './styled';

const renderMotionPaths = (closedColor: string, openColor: string): any[] => {
  const props = [
    {
      id: 1,
      variants: {
        closed: {
          d: 'M 2 2.5 L 20 2.5',
          stroke: closedColor,
          transition: { stroke: { delay: 0.1 } },
        },
        open: {
          d: 'M 3 16.5 L 17 2.5',
          stroke: openColor,
          transition: { stroke: { delay: 0.1 } },
        },
      },
    },
    {
      id: 2,
      d: 'M 2 9.423 L 20 9.423',
      variants: {
        closed: {
          opacity: 1,
          stroke: closedColor,
          transition: { stroke: { delay: 0.1 } },
        },
        open: {
          opacity: 0,
          stroke: openColor,
          transition: { stroke: { delay: 0.1 } },
        },
      },
    },
    {
      id: 3,
      variants: {
        closed: {
          d: 'M 2 16.346 L 20 16.346',
          stroke: closedColor,
          transition: { stroke: { delay: 0.1 } },
        },
        open: {
          d: 'M 3 2.5 L 17 16.346',
          stroke: openColor,
          transition: { stroke: { delay: 0.1 } },
        },
      },
    },
  ];

  return props.map(({ id, d, variants }) => {
    return <motion.path key={id} fill="transparent" strokeWidth="2.5" strokeLinecap="square" d={d} variants={variants} />;
  });
};

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  transition?: Transition;
};

const HamburgerButton: FC<Props> = ({ setIsOpen, isOpen, transition }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Hamburger initial={false} animate={isOpen ? 'open' : 'closed'} transition={transition ?? { type: 'tween' }}>
      <Button pathColor={isOpen ? 'secondary' : 'surface'} type="button" onClick={() => setIsOpen(!isOpen)}>
        <svg width="20" height="20" viewBox="0 0 23 18">
          {themeContext && renderMotionPaths(themeContext.colors.onSurface, '#000000')}
        </svg>
      </Button>
    </Hamburger>
  );
};

export default HamburgerButton;
