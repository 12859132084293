import { useAppSelector } from 'hooks/hooks';
import { useEffect } from 'react';
import { showToast } from 'utils/toastUtils';
import { StyledToastContainer } from './styled';

export const ErrorDisplay = () => {
  const errorState = useAppSelector(state => state.error);

  useEffect(() => {
    if (errorState.counter) {
      const { title, message } = errorState;
      showToast(title, message);
    }
  }, [errorState]);

  return <StyledToastContainer />;
};
