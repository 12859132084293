import { DropdownMenu } from 'components/UI/DropdownMenu/DropDownMenu';
import { useLocation, useSearchParams } from 'react-router-dom';
import { NavBar } from 'components/fragments/NavBar';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setCurrentEnvironment } from 'store/envState/envState';
import { getAvailableEnvironments } from 'utils/userRolesUtils/userRolesUtils';
import { StyledDropDownWrapper, TargetedEnvironment } from './styled';
import { CurrentUserDisplay } from '../CurrentUserDisplay/CurrentUserDisplay';

interface DropDownProps {
  environmentsList: Array<string>;
  currentEnvironment: string;
  onChange: (value: string) => void;
}
export const DropDownWrapper = ({
  environmentsList,
  currentEnvironment,
  onChange,
}: DropDownProps) => (
  <StyledDropDownWrapper>
    <DropdownMenu
      optionList={environmentsList}
      onChangeFunction={onChange}
      margin="0px"
      value={currentEnvironment}
      label="Targeted Environment:"
    />
  </StyledDropDownWrapper>
);

export const PageHeader = () => {
  const { role } = useAppSelector((state) => state.auth.session);
  const availableEnvironments = getAvailableEnvironments(role);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentEnvironment = searchParams.get('env') ?? 'qa';

  const location = useLocation();
  const dispatch = useAppDispatch();
  /*
  useEffect(() => {
    dispatch(setCurrentEnvironment(currentEnvironment));
  }, [currentEnvironment]);*/

  const updateParam = (newParams: Record<string, string>) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, ...newParams };
    setSearchParams(params, { replace: true });
  };

  const onEnvironmentChange = (value: string) => {
    updateParam({ env: value });
    dispatch(setCurrentEnvironment(value));
  };

  const renderTargetedEnvironmentComponent = () => {
    if (location.pathname !== '/') {
      if (role === 'Admin' || role === 'Support') {
        return (
          <DropDownWrapper
            environmentsList={availableEnvironments}
            onChange={onEnvironmentChange}
            currentEnvironment={currentEnvironment}
          />
        );
      }
      return <TargetedEnvironment>Targeted Environment: {currentEnvironment} </TargetedEnvironment>;
    }
    return undefined;
  };
  return (
    <NavBar
      setMobileMenuOpen={() => null}
      mobileMenuOpen={false}
      targetedEnvironment={renderTargetedEnvironmentComponent()}
      searchParams={searchParams}
      sideArea={location.pathname !== '/' ? <CurrentUserDisplay /> : undefined}
    />
  );
};
