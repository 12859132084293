import React from 'react';

interface SubmitEventWithSubmitter extends Omit<SubmitEvent, 'submitter'> {
  readonly submitter: HTMLElement
}

function isReactSubmitEvent(event: React.BaseSyntheticEvent<object, any, any> | undefined): event is React.BaseSyntheticEvent<SubmitEvent, any, any> {
  return !!event && 'submitter' in event.nativeEvent;
}

export function isReactSubmitEventWithSubmitter(event: React.BaseSyntheticEvent<object, any, any> | undefined)
  : event is React.BaseSyntheticEvent<SubmitEventWithSubmitter, any, any> {
  return isReactSubmitEvent(event) && !!event.nativeEvent.submitter;
}
