import React, { MouseEvent } from 'react';
import { StyledButton, Colors } from './styled';

export const Button = ({
  children,
  onClick,
  disabled = false,
  marginRight = false,
  color = Colors.Primary,
  type = 'button',
  widthPercent = '80%',
  id = '',
}: {
  children: any;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  color?: Colors;
  type?: 'button' | 'submit' | 'reset';
  marginRight?: boolean;
  widthPercent?: string;
  id?: string;
}) => {
  return (
    <StyledButton
      type={type}
      color={color}
      onClick={onClick}
      marginRight={marginRight}
      disabled={disabled}
      widthPercent={widthPercent}
      id={id}
    >
      {children}
    </StyledButton>
  );
};

export { Colors };
