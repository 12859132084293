export class ToolsError extends Error {
  public readonly title: string;

  constructor(title: string, message: string) {
    super(message);
    this.title = title;
  }

  public static fromError(title: string, error: Error | string) {
    if (typeof error === 'string') {
      return new ToolsError(title, error);
    }
    return new ToolsError(title, error.message);
  }
  public static isToolsError(error: any): error is ToolsError {
    return typeof (error as ToolsError).title === 'string';
  }
}
