import { createSlice, Draft, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RTKQueryError } from 'models/errors/errorModels';
import { ToolsError } from 'models/errors/ToolsError';
import { Dispatch } from 'react';
import { loginFailed } from 'store/authState/authState';
import { setRTKQueryErrorInfo } from 'utils/errorUtils';

type ErrorState = {
  counter: number,
  title: string,
  message: string,
};

const defaultErrorState: ErrorState = {
  counter: 0,
  title: '',
  message: '',
};

/* eslint-disable no-param-reassign */
const errorUpdate = (state: Draft<ErrorState>, action: PayloadAction<ToolsError>) => {
  state.counter += 1;
  state.title = action.payload.title;
  state.message = action.payload.message;
};
/* eslint-enable no-param-reassign */

export const errorSlice = createSlice({
  name: 'error',
  initialState: defaultErrorState,
  reducers: {
    displayError: (state, action: PayloadAction<ToolsError>) => {
      errorUpdate(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginFailed, (state, action: PayloadAction<ToolsError>) => {
        errorUpdate(state, action);
      });
  },
});

export const errorReducer = errorSlice.reducer;
export const { displayError } = errorSlice.actions;

export const handleRTKQueryError = (error: RTKQueryError | SerializedError) => (dispatch: Dispatch<any>) => {
  const [title, message] = setRTKQueryErrorInfo(error);
  const toolsError = new ToolsError(title, message);
  dispatch(displayError(toolsError));
};
