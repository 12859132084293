import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toolsApi } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { handleRTKQueryError } from 'store/errorState/errorState';
import { browserDownloadFile } from 'utils/downloadUtils';
import { MdDownloadForOffline } from 'react-icons/md';
import { StyledMapFilesPresentation, ShowMapImg, DownloadFileButton, MapInfo } from './styled';
import { FormInput, MapFilesForm } from './MapFilesForm';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../constants';
import { getMapData } from './MapFilesView';

export const MapFilesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramMapType = searchParams.get('mapType') ?? 'siteMap';
  const [mapType, setMapType] = useState<string | undefined>(paramMapType);
  const paramMowerId = searchParams.get('mowerId') ?? undefined;
  const [mowerId, setMowerId] = useState<string | undefined>(paramMowerId);
  const [getMapFiles, { data, error: queryError, isFetching }] = toolsApi.useLazyGetMapFileQuery();

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  // Wrapper to both set the mowerId (and trigger a new api request) and
  // to update the url
  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, mowerId: formData.mowerId, mapType: formData.mapType };
    setSearchParams(params, { replace: true });
    setMapType(formData.mapType);
    setMowerId(formData.mowerId);
    getMapFiles({ mowerId: formData.mowerId, mapType: formData.mapType });
  };

  const [isDownloaded, setIsDownloaded] = useState(false);
  const onDownloadFile = () => {
    browserDownloadFile(new Blob([data], { type: 'text/plain' }), `${mowerId}-${mapType}.svg`);
    setIsDownloaded(true);
    setTimeout(() => {
      setIsDownloaded(false);
    }, 1000);
  };

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      mowerId,
      mapType,
    },
  });

  return (
    <>
      <ToolSideBar>
        <MapFilesForm onSubmit={handleSubmit(onSubmit)} register={register} errors={formErrors} />
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledMapFilesPresentation>
          <h1>Map Files</h1>
          {isFetching && !queryError && <h3>Fetching map file for {mowerId}...</h3>}
          {queryError && (
            <div>
              <h2>Map file fetching failed</h2>
            </div>
          )}
          {mapType && data?.length === 0 && !isFetching && !queryError && (
            <>
              <DownloadFileButton onClick={onDownloadFile}>
                {isDownloaded ? (
                  <MdDownloadForOffline size={30} color="green" />
                ) : (
                  <MdDownloadForOffline size={30} />
                )}
              </DownloadFileButton>
              <MapInfo>Can not visualize the fetched map file because it is empty </MapInfo>
            </>
          )}
          {mapType && data && !isFetching && !queryError && (
            <>
              <h3>Map files response</h3>
              <DownloadFileButton onClick={onDownloadFile}>
                {isDownloaded ? (
                  <MdDownloadForOffline size={30} color="green" />
                ) : (
                  <MdDownloadForOffline size={30} />
                )}
              </DownloadFileButton>
              <ShowMapImg src={getMapData(data, mapType)} alt="Could not visualize the map" />
            </>
          )}
          <hr />
          <h4>Instructions</h4>
          <p>
            To use this tool, choose the environment in the page top panel to which the mower is
            currently pointing.
          </p>
          <p>
            In the form, you can input either the device ID or the IPR. Input the nine-digit serial
            number of the mower.
          </p>
          <p>Map files are only suported by G4 mowers.</p>
          <p>Then choose the type of map file you wish to view or download.</p>
        </StyledMapFilesPresentation>
      </StyledPageWrapper>
    </>
  );
};
