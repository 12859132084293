import { ToolsError } from 'models/errors/ToolsError';
import { isPairingWithTimestamp, Pairing, PairingWithTimestamp } from 'models/pairingModels';

const isPairingWithTimestampArray = (items: any): items is PairingWithTimestamp[] => {
  return Array.isArray(items) && items.every(isPairingWithTimestamp);
};

export const getPairingHistory = () => {
  const history = localStorage.getItem('pairingHistory');
  if (history) {
    const currentHistory = JSON.parse(history);
    if (isPairingWithTimestampArray(currentHistory)) {
      return currentHistory;
    }
    throw new ToolsError('Pairing history error', 'Pairing history was in an incorrect format.');
  }
  return [];
};

export const storePairingInHistory = (pairing: Pairing) => {
  const currentHistory = getPairingHistory();
  currentHistory.push({
    time: Date.now(),
    pairing,
  });
  localStorage.setItem('pairingHistory', JSON.stringify(currentHistory));
};
