import styled from 'styled-components';

export const StyledTextInput = styled.input`
  display: block;
  width: 125%;
  margin: auto;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 0.4em;
  height: 28px;
  border-radius: 14px;
  border: 1px solid #d2d2d1;
  font-size: 14px;
`;
