import styled from 'styled-components';

export const StyledMowerEnvironmentPresentation = styled.div`
  h2 {
    color: red;
  }
  h3 {
    color: green;
  }
`;

export const StyledMowerEnvironmentInfo = styled.div`
  padding-left: 16px;
`;
