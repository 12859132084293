import styled from 'styled-components';
import { breakpoints } from '../../../styles/GlobalStyles';
// import HqvLogo from '../../../assets/vectors/logo.svg';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.onSecondary};
  z-index: 199;
`;

export const TopBorder = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.surface};

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 56px;

  @media (max-width: ${breakpoints.m}px) {
    height: 100px;
  }
`;

export const PrimaryArea = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const SecondaryArea = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const SideArea = styled.div`
  display: flex;
  padding: 0 24px 0 8px;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const ActionMenu = styled.div`
  color: ${({ theme }) => theme.colors.surface};
  display: flex;
  @media (max-width: ${breakpoints.l}px) {
    svg {
      display: none;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  margin-left: 3%;
  justify-content: space-between;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    background: none;
    margin: 0px;
  }
`;

export const StyledH1 = styled.h1`
  padding-left: 1%;
  align-self: center;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;
