import styled from 'styled-components';
import { boxShadows } from 'styles';

export const StyledPairingsAtDateView = styled.div`
  font-size: 16px;
  box-shadow: ${boxShadows.normal};
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 20px;
  p {
    margin-top: 0;
    display: inline;
  }
`;

export const StyledCollapsibleContainer = styled.div<{ open?: boolean }>`
  max-height: ${({ open }) => (open ? '100vh;' : '0px;')};
  overflow: auto;
  transition: max-height 0.5s ease-in-out;
`;

export const StyledHeader = styled.div<{ open?: boolean }>`
  display: flex;
  justify-content: space-between;
  svg {
    width: 4%;
    transition: all 0.3s ease-out;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  h4 {
    margin: 5px 0;
  }
`;
