function padWithZero(digit: number) {
  return digit < 10 ? `0${digit}` : `${digit}`;
}

export function epochSecondsToDateString(timestamp: number) {
  const d = new Date(timestamp);
  return `${padWithZero(d.getFullYear())}-${padWithZero(d.getMonth() + 1)}-${padWithZero(
    d.getDate(),
  )} ${padWithZero(d.getHours())}:${padWithZero(d.getMinutes())}:${padWithZero(d.getSeconds())}`;
}
