import styled from 'styled-components';
import { defaultTheme } from 'styles';

export const StyledToolSidebar = styled.div`
  width: 350px;
  max-width: 350px;
  display: flex;
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  background-color: ${defaultTheme.colors.onPrimary};
  padding: 16px 0px 16px 16px;
  border-radius: 16px;
  margin: 3% 0% 0% 3%;
  height: max-content;

  button {
    margin: 15px 0px 10px 45px;
  }
`;
