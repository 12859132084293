import { FormEventHandler } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { Button } from 'components/UI/Button/Button';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { fieldRequiredMessage, incorrectEmailMessage } from 'assets/messages/errorMessages';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';

export interface FormInput {
  email: string;
}
export interface FormErrors {
  email?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholders?: Record<string, string>;
}

export const GprForm = ({ onSubmit, register, errors, placeholders = {} }: Props) => (
  <form onSubmit={onSubmit}>
    <StyledTextLabel htmlFor="email">Account Email:</StyledTextLabel>
    <StyledTextInput
      placeholder={placeholders.email}
      {...register('email', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        pattern: {
          value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          message: incorrectEmailMessage,
        },
      })}
    />
    {errors.email && <StyledFormError>{errors.email.message}</StyledFormError>}
    <Button type="submit">Show Report</Button>
    <Button>Download Report</Button>
  </form>
);

export default GprForm;
