import { FormEventHandler, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { Button } from 'components/UI/Button/Button';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import {
  fieldRequiredMessage,
  incorrectDeviceIdMessage,
  incorrectIprIdMessage,
} from 'assets/messages/errorMessages';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { StyledRadioLabel } from 'components/UI/RadioLabel/StyledRadioLabel';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';

export interface FormInput {
  mowerId: string;
  mapType: string;
}
export interface FormErrors {
  mowerId?: FieldError;
  mapType?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const MapFilesForm = ({ onSubmit, register, errors, placeholder = '' }: Props) => {
  const [idFormat, setIdFormat] = useState<'deviceId' | 'iprId'>('deviceId');
  const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdFormat(event.target.value as 'deviceId' | 'iprId');
  };
  return (
    <form aria-label="form" onSubmit={onSubmit}>
      <StyledTextLabel htmlFor="mowerId">Mower Id:</StyledTextLabel>
      <StyledRadioLabel>
        <input
          type="radio"
          value="deviceId"
          checked={idFormat === 'deviceId'}
          onChange={handleFormatChange}
        />
        Device Id
      </StyledRadioLabel>
      <StyledRadioLabel>
        <input
          type="radio"
          value="iprId"
          checked={idFormat === 'iprId'}
          onChange={handleFormatChange}
        />
        Ipr Id
      </StyledRadioLabel>
      <StyledTextInput
        id="mowerId"
        {...register('mowerId', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          setValueAs: autofillG4ComboardId(),
          pattern: {
            value:
              idFormat === 'deviceId'
                ? /^\d{9}-\d{9}$/
                : /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/,
            message: idFormat === 'deviceId' ? incorrectDeviceIdMessage : incorrectIprIdMessage,
          },
        })}
        placeholder={
          placeholder ||
          (idFormat === 'deviceId' ? '123456789-123456789' : 'a1a1a1a1-a1a1-a1a1-a1a1-a1a1a1a1a1a1')
        }
      />
      {errors.mowerId && <StyledFormError>{errors.mowerId.message}</StyledFormError>}
      <StyledRadioLabel>
        <input {...register('mapType')} type="radio" name="mapType" value="siteMap" />
        Site Map
      </StyledRadioLabel>
      <StyledRadioLabel>
        <input {...register('mapType')} type="radio" name="mapType" value="zoneMap" />
        Zone Map
      </StyledRadioLabel>
      <StyledRadioLabel>
        <input {...register('mapType')} type="radio" name="mapType" value="generatedMap" />
        Generated Map
      </StyledRadioLabel>
      <Button type="submit">FETCH SVG</Button>
    </form>
  );
};

export default MapFilesForm;
