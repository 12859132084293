import { useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { tools, roles } from '../../../constants';
import TabMenu from '../TabMenu';

const constructMenu = (role: string, searchParams: URLSearchParams) => {
  const roleAccessChecker = createRoleAccessChecker(role);
  const filteredMenuItems = tools.filter((tool) => {
    const formattedToolName = tool.replace(/\s+/g, '-').toLowerCase();
    return roleAccessChecker(formattedToolName);
  });

  let idCounter = 0;
  const constructedMenu = filteredMenuItems.map((tool) => {
    const formattedToolName = tool.replace(/\s+/g, '-').toLowerCase();
    idCounter += 1;
    return {
      id: idCounter,
      text: tool,
      link: `/${formattedToolName}?${searchParams.toString()}`,
    };
  });
  return constructedMenu;
};

export const MenuAuthWrapper = ({ searchParams }: { searchParams: URLSearchParams }) => {
  const {
    session: { role },
  } = useAppSelector((state) => state.auth);
  if (role && role !== roles.Unauthorized) {
    const roleGeneratedMenu = constructMenu(role, searchParams);
    return <TabMenu menu={roleGeneratedMenu} />;
  }
  return null;
};
