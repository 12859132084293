import { fieldRequiredMessage, incorrectDeviceIdMessage } from 'assets/messages/errorMessages';
import { Button } from 'components/UI/Button/Button';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { StyledRadioLabel } from 'components/UI/RadioLabel/StyledRadioLabel';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { FormEventHandler } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';

export interface FormInput {
  mowerId: string;
  newEnvironment: 'dev' | 'qa' | 'live';
}
export interface FormErrors {
  mowerId?: FieldError;
  newEnvironment?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const MowerEnvironmentForm = ({ onSubmit, register, errors, placeholder = '' }: Props) => (
  <form onSubmit={onSubmit}>
    <StyledTextLabel htmlFor="mowerId">Mower Id:</StyledTextLabel>
    <StyledTextInput
      {...register('mowerId', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        setValueAs: autofillG4ComboardId(),
        pattern: {
          value: /^\d{9}-\d{9}$/,
          message: incorrectDeviceIdMessage,
        },
      })}
      placeholder={placeholder}
    />
    {errors.mowerId && <StyledFormError>{errors.mowerId.message}</StyledFormError>}
    <StyledTextLabel>Change environment to:</StyledTextLabel>
    <StyledRadioLabel>
      <input {...register('newEnvironment')} type="radio" name="newEnvironment" value="dev" />
      dev
    </StyledRadioLabel>
    <StyledRadioLabel>
      <input {...register('newEnvironment')} type="radio" name="newEnvironment" value="qa" />
      qa
    </StyledRadioLabel>
    <StyledRadioLabel>
      <input {...register('newEnvironment')} type="radio" name="newEnvironment" value="live" />
      live
    </StyledRadioLabel>
    <Button type="submit">Change</Button>
  </form>
);
