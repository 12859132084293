import styled from 'styled-components';
import { defaultTheme } from 'styles';

type DropdownProps = {
  width: string | undefined;
  margin: string | undefined;
};

export const StyledSelect = styled.select<DropdownProps>`
  ${({ width }) => width && `width: ${width}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  padding: 0.3em;
  font-family: ${defaultTheme.fontFamily};
  font-size: 16px;
  text-align: left;
  border: 1px solid #d2d2d1;
  border-radius: 10px;
`;

export const StyledOption = styled.option`
  color: #3d3d3c;
  padding: 0px;
`;
