import styled from 'styled-components';

type LabelProps = {
  display?: string;
  margin?: string;
};

export const StyledTextLabel = styled.label<LabelProps>`
  color: #686867;
`;
