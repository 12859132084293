import { rest } from 'msw';
import {
  mockedGdprReport,
  mockedMapFile,
  mockedPairingResponse,
  mockedRemoteDebugSessions,
  mockedStatus,
  mockedTwin,
  mockedAllowedInLiveTrue,
  mockedAllowedInLiveFalse,
  mockedError,
  mockedTimeOut,
  mockedstartedAtTimestampZero,
  mockedstartedAtTimestampStarted,
} from './mockData';

localStorage.setItem('getRemoteDebugSession', 0);

localStorage.setItem('retryMowerLogIn', 0);

const prefix = '/tools/v1';
export const handlers = [
  rest.get(`${prefix}/mowers/:mowerId/status`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedStatus));
  }),

  rest.get(`${prefix}/mowers/:mowerId/twin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedTwin));
  }),

  rest.delete(`${prefix}/mowers/:mowerId/twin`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ message: 'OK' }));
  }),

  rest.post(`${prefix}/gdpr`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedGdprReport));
  }),

  rest.get(`${prefix}/tools/v1/mowers/:mowerId/map`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.text(mockedMapFile));
  }),

  rest.post(`${prefix}/mowers/pairing`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedPairingResponse));
  }),

  rest.get(`${prefix}/mowers/remote-debug-sessions`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedRemoteDebugSessions));
  }),

  rest.post(`${prefix}/mowers/:serialNumber-999999999/remoteDebugSession`, (req, res, ctx) => {
    const { serialNumber } = req.params;

    if (serialNumber === '456789123') {
      return res(ctx.status(500), ctx.json(mockedError));
    }
    return res(ctx.status(200));
  }),

  rest.get(`${prefix}/mowers/:serialNumber-999999999/remote-debug-session`, (req, res, ctx) => {
    const { serialNumber } = req.params;

    if (serialNumber === '567891234' && localStorage.getItem('getRemoteDebugSession') < 15) {
      localStorage.setItem(
        'getRemoteDebugSession',
        +localStorage.getItem('getRemoteDebugSession') + 1,
      );
      return res(ctx.status(200), ctx.json(mockedstartedAtTimestampZero));
    }

    if (serialNumber === '567891244') {
      return res(ctx.status(200), ctx.json(mockedstartedAtTimestampZero));
    }

    if (serialNumber === '678912345') {
      return res(ctx.status(500), ctx.json(mockedError));
    }

    localStorage.setItem('getRemoteDebugSession', 0);
    return res(ctx.status(200), ctx.json(mockedstartedAtTimestampStarted));
  }),

  rest.get(`${prefix}/mowers/:serialNumber-999999999/allowed-in-live`, (req, res, ctx) => {
    const { serialNumber } = req.params;

    if (serialNumber === '123456789') {
      return res(ctx.status(500), ctx.json(mockedError));
    }
    if (serialNumber === '234567891') {
      return res(ctx.status(200), ctx.json(mockedAllowedInLiveFalse));
    }
    return res(ctx.status(200), ctx.json(mockedAllowedInLiveTrue));
  }),

  rest.post(`${prefix}/mowers/proxyincloud/ownership`, async (req, res, ctx) => {
    const request = await req.json();

    if (request.serialNumber === 345678912) {
      return res(ctx.status(500), ctx.json(mockedError));
    }

    return res(ctx.status(200));
  }),

  rest.post(
    `${prefix}/mowers/:serialNumber-999999999/proxyincloud/mower-log-in`,
    async (req, res, ctx) => {
      const { serialNumber } = req.params;

      if (serialNumber === '395678912') {
        return res(ctx.status(404), ctx.json(mockedError));
      }
      if (serialNumber === '222222222') {
        return res(ctx.status(500), ctx.json(mockedError));
      }
      if (localStorage.getItem('retryMowerLogIn') < 1) {
        localStorage.setItem('retryMowerLogIn', +localStorage.getItem('retryMowerLogIn') + 1);
        return res(ctx.status(504), ctx.json(mockedTimeOut));
      }
      if (localStorage.getItem('retryMowerLogIn') < 3) {
        localStorage.setItem('retryMowerLogIn', +localStorage.getItem('retryMowerLogIn') + 1);
        return res(ctx.status(500), ctx.json(mockedError));
      }

      localStorage.setItem('retryMowerLogIn', 0);
      return res(ctx.status(200));
    },
  ),

  rest.put(
    `${prefix}/mowers/:serialNumber-999999999/proxyincloud/amc-backend-host`,
    (req, res, ctx) => {
      const { serialNumber } = req.params;
      if (serialNumber === '912345678') {
        return res(ctx.status(500), ctx.json(mockedError));
      }
      return res(ctx.status(200));
    },
  ),

  rest.get(
    `${prefix}/mowers/:serialNumber-999999999/proxyincloud/amc-backend-reconnect`,
    (req, res, ctx) => {
      const { serialNumber } = req.params;
      if (serialNumber === '112345678') {
        return res(ctx.status(500), ctx.json(mockedError));
      }
      return res(ctx.status(200));
    },
  ),
];
