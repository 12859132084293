import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toolsApi } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { handleRTKQueryError } from 'store/errorState/errorState';
import ReactJson from 'react-json-view';
import MowerStatusForm, { FormInput } from './MowerStatusForm';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../constants';
import { StyledMowerStatusPresentation } from './styled';

export const MowerStatusPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramMowerId = searchParams.get('mowerId') ?? undefined;
  const [mowerId, setMowerId] = useState<string | undefined>(paramMowerId);
  const [getMowerStatus, { data, error: queryError, isFetching }] =
    toolsApi.useLazyGetMowerStatusQuery();

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, mowerId: formData.mowerId };
    setSearchParams(params, { replace: true });
    setMowerId(formData.mowerId);
    getMowerStatus(formData.mowerId);
  };

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  // React-forms stuff
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      mowerId: mowerId ?? '',
    },
  });

  return (
    <>
      <ToolSideBar>
        <MowerStatusForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
        />
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledMowerStatusPresentation>
          <h1>Mower Status</h1>
          {isFetching && !queryError && <h3>Loading mower status for {mowerId}...</h3>}
          {queryError && (
            <div>
              <h2>Failed to fetch mower status</h2>
            </div>
          )}
          {!(isFetching && queryError) && data && (
            <>
              <h3>Fetched mower status successfully!</h3>
              <ReactJson src={data} displayObjectSize={false} collapsed={1} name={false} />
            </>
          )}
          <hr />
          <h4>Instructions</h4>
          <p>
            To use this tool, choose the environment in the page top panel to which the mower is
            currently pointing.
          </p>
          <p>
            In the form, you can input either the device ID or the IPR. The device ID for a G3 mower
            is the nine-digit serial number of the mower,
          </p>
          <p>
            followed by a dash, followed by the nine-digit comboard ID. For G4 mowers, just input
            the nine-digit serial number of the mower.
          </p>
        </StyledMowerStatusPresentation>
      </StyledPageWrapper>
    </>
  );
};

export default MowerStatusPage;
