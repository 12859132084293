import styled from 'styled-components';
import { defaultTheme } from 'styles';

export const StyledPageWrapper = styled.div`
  width: 100%;
  padding: 2.5% 20px 0 20px;
  margin-bottom: 20px;
  background-color: ${defaultTheme.colors.background};
  overflow: auto;
`;
