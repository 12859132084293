export const browserDownloadFile = (data: Blob | File, fileName: string) => {
  const objectURL = URL.createObjectURL(data);
  const downloadElement = document.createElement('a');
  downloadElement.href = objectURL;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();

  // Remove element and object URL after download to prevent memory leaks
  document.body.removeChild(downloadElement);
  URL.revokeObjectURL(objectURL);
};

export const browserDownloadJsonFile = (data: object, fileName: string) => {
  const jsonData = JSON.stringify(data, null, 4);
  const blob = new Blob([jsonData], { type: 'application/json' });
  browserDownloadFile(blob, fileName);
};
