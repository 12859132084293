import styled from 'styled-components';

export const StyledGdprPresentation = styled.pre`
  `;

export const FailedFetch = styled.h2`
  color: red;
`;

export const SuccessfullFetch = styled.h3`
  color: green;
`;

export const StyledHeading = styled.h3`
  font-weight: bold;
  margin-bottom: 0px;
  display: inline;
`;

export const StyledSubHeading = styled.h5`
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const StyledP = styled.p`
  display: inline;
  padding-left: 4px;
`;

export const StyledSubP = styled.p`
  padding-left: 12px;
  margin: 4px 0px 8px 0px;
`;

export const CopyToClipboardStyled = styled.div`
 max-width: 60px;
 float: right;
 margin-right: 50px;
`;
