import styled from 'styled-components';

export const StyledMapFilesPresentation = styled.pre``;

export const ShowMapImg = styled.img`
  background-color: ${({ theme }) => theme.colors.grey3};
  border-radius: 12px;
  width: 50%;
  margin-left: 50px;
  margin-bottom: 45px;
`;

export const DownloadFileButton = styled.button`
  background-color: ${({ theme }) => theme.colors.grey3};
  position: fixed;
  cursor: pointer;
`;

export const MapInfo = styled.h4`
  margin-left: 50px;
`;
