import styled from 'styled-components';
import { breakpoints } from 'styles/GlobalStyles';

export const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 10%;
  }

  p {
    padding: 2% 26% 0% 26%;
  }
  h4 {
    margin: 2px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    p {
      padding: 2% 15% 0% 15%;
    }
  }
`;
